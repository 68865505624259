// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cenik-jsx": () => import("./../../../src/pages/cenik.jsx" /* webpackChunkName: "component---src-pages-cenik-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-in-informacije-jsx": () => import("./../../../src/pages/kontakt-in-informacije.jsx" /* webpackChunkName: "component---src-pages-kontakt-in-informacije-jsx" */),
  "component---src-pages-obisci-rudnik-animirana-vodenja-jsx": () => import("./../../../src/pages/obisci-rudnik/animirana-vodenja.jsx" /* webpackChunkName: "component---src-pages-obisci-rudnik-animirana-vodenja-jsx" */),
  "component---src-pages-obisci-rudnik-glavni-rov-jsx": () => import("./../../../src/pages/obisci-rudnik/glavni-rov.jsx" /* webpackChunkName: "component---src-pages-obisci-rudnik-glavni-rov-jsx" */),
  "component---src-pages-obisci-rudnik-izvozni-rov-jsx": () => import("./../../../src/pages/obisci-rudnik/izvozni-rov.jsx" /* webpackChunkName: "component---src-pages-obisci-rudnik-izvozni-rov-jsx" */),
  "component---src-pages-obisci-rudnik-kulinarika-jsx": () => import("./../../../src/pages/obisci-rudnik/kulinarika.jsx" /* webpackChunkName: "component---src-pages-obisci-rudnik-kulinarika-jsx" */),
  "component---src-pages-obisci-rudnik-tradicionalni-dogodki-jsx": () => import("./../../../src/pages/obisci-rudnik/tradicionalni-dogodki.jsx" /* webpackChunkName: "component---src-pages-obisci-rudnik-tradicionalni-dogodki-jsx" */),
  "component---src-pages-obisci-rudnik-trgovina-in-spominki-jsx": () => import("./../../../src/pages/obisci-rudnik/trgovina-in-spominki.jsx" /* webpackChunkName: "component---src-pages-obisci-rudnik-trgovina-in-spominki-jsx" */),
  "component---src-pages-politika-varstva-osebnih-podatkov-jsx": () => import("./../../../src/pages/politika-varstva-osebnih-podatkov.jsx" /* webpackChunkName: "component---src-pages-politika-varstva-osebnih-podatkov-jsx" */),
  "component---src-pages-razisci-rudnik-sitarjevec-jsx": () => import("./../../../src/pages/razisci-rudnik-sitarjevec.jsx" /* webpackChunkName: "component---src-pages-razisci-rudnik-sitarjevec-jsx" */),
  "component---src-templates-mine-product-js": () => import("./../../../src/templates/MineProduct.js" /* webpackChunkName: "component---src-templates-mine-product-js" */)
}

