import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "@popperjs/core/dist/umd/popper.min";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-modal-video/scss/modal-video.scss";
import './src/assets/css/plugins.css';
import './src/assets/scss/style.scss';
import Layout from './src/components/layouts/Layout';
import('cookieconsent');

export function wrapPageElement({element, props}) {
    return (
        <Layout { ...props }>
            { element }
        </Layout>
    );
};

export const onServiceWorkerUpdateReady = () => window.location.reload();

export const onClientEntry = () => {
    if (!(`IntersectionObserver` in window)) {
        import(`intersection-observer`);
    }

    window.onload = () => {
        window.cookieconsent.initialise({
            "palette": {
                "popup": {
                    "background": "#D0682D"
                },
                "button": {
                    "background": "#ffffff"
                }
            },
            "position": "bottom-left",
            "content": {
                "message": "Ta stran uporablja piškotke. Z nadaljevanjem uporabe te strani soglašate z uporabo piškotkov.",
                "dismiss": "Naprej",
                "link": "Več o piškotkih",
                "href": "https://rudniksitarjevec.si/politika-zasebnosti"
            }
        });
    }
};